import { ajax } from "@/pages/oj/api";

export default {

    getBoardNoticeList(params){
        return ajax('board/announce', 'get', {
            params
        });
    },


// 210302추가
    getNoticeList (params) {
        // params.limit = limit
        // params.offset = offset
        return ajax('notice-list', 'get', {
            params
        })
    },
    getNoticeFromNoticeList(params){
        return ajax('notice/announce', 'get', {
            params
        });
    },

// 0302추가
    getNoticeDetail(params){
        return ajax('notice', 'get', {
            params: {
                board_id: params
            }
        })
    },

    postNotice(title, content, created_by_id, type, company, isNotice){
        return ajax('notice/', 'post',{
            data:{
                title,
                content,
                created_by_id,
                type,
                company,
                isNotice
            }
        })
    },

    putNotice(title, content, parameter, type,isNotice){
        return ajax('notice/', 'put', {
            data: {
                title,
                content,
                board_id: parameter,
                type: type,
                isNotice
            }
        })
    },


// 210302추가
    deleteNotice(parameter){
        return ajax('notice/', 'delete', {
            params: {
                board_id: parameter
            }
        })
    },

// 210302 추가
    noticepostComment(comment, board_id, created_by_id, type){
        return ajax('notice-comment/', 'post',{
            data:{
                comment,
                board_id,
                created_by_id,
                type
            }
        })
    },

// 210303추가
    putNoticeComment(comment_id,comment){
        return ajax('notice-comment/', 'put', {
            data: {
                comment_id,
                comment
            }
        })
    },

// 210303 추가
    deleteNoticeComment(comment_id){
        return ajax('notice-comment/', 'delete', {
            params: {
                comment_id
            }
        })
    },
// 0303 추가
    deleteNoitceComment(comment_id){
        return ajax('notice-comment/', 'delete', {
            params: {
                comment_id
            }
        })
    },

    // 210303 추가
    secretNotice(board_id, type){
        return ajax('notice-secret', 'put', {
            data:{
                board_id,
                type
            }
        })
    },


    secretNoticeComment(comment_id, type){
        return ajax('notice-comment-secret', 'put', {
            data:{
                comment_id,
                type
            }
        })
    },

    changeNoticeAnnounce(board_id, isNotice){
        return ajax('notice/change-announce/', 'put', {
            data:{
                board_id,
                isNotice
            }
        })
    },





}
