import { ajax } from "@/pages/oj/api";

export default {
    getEnterPriseContestList (offset, limit, searchParams, board_name) {
        let params = {
            offset,
            limit,
            board_name : board_name
        }
        if (searchParams !== undefined) {
            Object.keys(searchParams).forEach((element) => {
                if (searchParams[element]) {
                    params[element] = searchParams[element]
                }
            })
        }
        return ajax('enterprise-contests', 'get', {
            params
        })
    },
    getContestList (offset, limit, searchParams) {
        let params = {
            offset,
            limit
        }
        if (searchParams !== undefined) {
            Object.keys(searchParams).forEach((element) => {
                if (searchParams[element]) {
                    params[element] = searchParams[element]
                }
            })
        }
        return ajax('contests', 'get', {
            params
        })
    },
    getContest (id) {

        return ajax('contest', 'get', {
            params: {
                id
            }
        })
    },
    getContestAccess (contestID) {
        return ajax('contest/access', 'get', {
            params: {
                contest_id: contestID
            }
        })
    },
    checkContestPassword (contestID, password) {
        return ajax('contest/password', 'post', {
            data: {
                contest_id: contestID,
                password
            }
        })
    },
    getContestAnnouncementList (contestId) {
        return ajax('contest/announcement', 'get', {
            params: {
                contest_id: contestId
            }
        })
    },
    getContestAnnouncement (contestId, announcementId) {
        return ajax('contest/announcement-detail', 'get', {
            params: {
                contest_id: contestId,
                announcement_id: announcementId
            }
        })
    },


    getUserRank (offset, limit, rule = 'acm') {
        let params = {
            offset,
            limit,
            rule
        }
        return ajax('user_rank', 'get', {
            params
        })
    },
    getContestRank (params) {
        return ajax('contest_rank', 'get', {
            params
        })
    },
    getContestRankCsv (params) {
        return ajax('contest_rank_csv', 'get', {
            params
        })
    },

    getACMACInfo (params) {
        return ajax('admin/contest/acm_helper', 'get', {
            params
        })
    },
    updateACInfoCheckedStatus (data) {
        return ajax('admin/contest/acm_helper', 'put', {
            data
        })
    },
};
