import Vue from 'vue'
import store from '@/store'
import axios from 'axios'
import user from "@/pages/oj/util/api/user"
import problem from "@/pages/oj/util/api/problem"
import contest from "@/pages/oj/util/api/contest";
import submission from "@/pages/oj/util/api/submission";
import board from "@/pages/oj/util/api/board";
import problemStep from "@/pages/oj/util/api/problemStep";
import setting from "@/pages/oj/util/api/etc";
import reference from "@/pages/oj/util/api/reference";
import notice from "@/pages/oj/util/api/notice";
import e_learning from "@/pages/oj/util/api/e_learning";
Vue.prototype.$http = axios
axios.defaults.baseURL = '/api'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

export default {


  ...user,
  ...problem,
  ...contest,
  ...submission,
  ...board,
  ...problemStep,
  ...setting,
  ...reference,
  ...notice,
  ...e_learning
}

/**
 * @param url
 * @param method get|post|put|delete...
 * @param params like queryString. if a url is index?a=1&b=2, params = {a: '1', b: '2'}
 * @param data post data, use for method put|post
 * @returns {Promise}
 */
export function ajax (url, method, options) {
  if (options !== undefined) {
    var {params = {}, data = {}} = options
  } else {
    params = data = {}
  }
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      params,
      data
    }).then(res => {
      // API는 오류 유무에 따라 오류가 있는지 여부를 정상적으로 반환합니다 (상태 = 20x).
      if (res.data.error !== null) {
        Vue.prototype.$error(res.data.data)
        reject(res)
        // 백엔드가 로그인으로 돌아 가면 세션이 유효하지 않으며 현재 로그인 한 사용자는 로그 아웃해야합니다.
        if (res.data.data.startsWith('Please login')) {
          store.dispatch('changeModalStatus', {'mode': 'login', 'visible': true})
        }
      } else {
        resolve(res)
        // if (method !== 'get') {
        //   Vue.prototype.$success('Succeeded')
        // }
      }
    }, res => {
      // API 요청이 비정상이며 일반적으로 서버 오류 또는 네트워크 오류입니다.
      reject(res)
      Vue.prototype.$error(res.data.data)
    })
  })
}