import { ajax } from "@/pages/oj/api";

export default {
    getProblemStepList() {
        // params = utils.filterEmptyValue(params)
        return ajax('problem_step', 'get', {
        })
    },
    getProblemStep(problem_step_id) {
        return ajax('problem_step', 'get', {
            params: {
                problem_step_id
            }
        })
    },

    // problem_step기반
    getProblemStepLevelList(problem_step_id) {
        return ajax('problem_step_level', 'get', {
            params: {
                problem_step_id
            }
        })
    },
    getStepLevel(problem_step_level_id) {
        return ajax('problem_step_level', 'get', {
            params: {
                problem_step_level_id
            }
        })
    },
    // problemStepLevelDetailList
    getProblemStepLevelDetailList(problem_step_level_id){
        return ajax('problem_step_level_detail', 'get', {
            params: {
                problem_step_level_id
            }
        })
    },
    getProblemStepLevelDetail(problem_step_level_detail_id){
        return ajax('problem_step_level_detail', 'get', {
            params: {
                problem_step_level_detail_id
            }
        })
    },
    putProblemStepLevelDetail(isFavorite){
        const id = isFavorite;
        return ajax('problem_step_level_detail', 'put', {
            data : id
        })
    },

};
