import api from "@oj/api";

const mixins = {
  async mounted() {
    if (this.$route.params["board_id"]) {
      this.index = this.$route.params["board_id"];
      await this.getELearning(this.$route.params["board_id"]);
      this.title = this.elearning.e_learning.title;
      this.content = this.elearning.e_learning.content;

      if (this.elearning.e_learning.flag === 3) {
        this.checked = true;
      } else {
        this.checked = false;
      }

      if (
        !this.isAdminRole &&
        this.elearning.e_learning.created_by?.id !== this.user.profile.user.id
      ) {
        this.$router.push({
          path: `/e_learnings/${this.$route.params.board_name}/${this.$route.params.company.toLowerCase()}`
        });
      }
    }
  },
  methods: {
    async write() {
      if (!this.isExistsTitle(this.title)) return;
      if (!this.isTitleTooLong(this.title)) return;
      if (!this.isExistsContent(this.content)) return;
      if (!this.isContentTooLong(this.content)) return;

      const test = await api.postELearning(
        this.title,
        this.content,
        this.user.profile.user.id,
        this.$route.params.company,
        this.checked === true ? "secret" : "normal",
        this.$route.params.board_name
      );
      this.$router.push({
        path: `/e_learnings/${this.$route.params.board_name.toLowerCase()}/${this.$route.params.company.toLowerCase()}`,
        query: { page: this.$route.query.page }
      });
    },
    update() {
      if (!this.isExistsTitle(this.title)) return;
      if (!this.isTitleTooLong(this.title)) return;
      if (!this.isExistsContent(this.content)) return;
      if (!this.isContentTooLong(this.content)) return;
      this.$Modal.confirm({
        content: "영상 강의를 수정 하시겠습니까?",
        onOk: async () => {
          if (
            this.isAdminRole ||
            this.elearning.e_learning.created_by?.id === this.user.profile.user.id
          ) {
            // console.log('--------')
            // console.log(this.elearning.e_learning.id);
            // console.log('--------')
            const test = await api.putELearning(
              this.title,
              this.content,
              this.checked === true ? "secret" : "normal",
              this.$route.params.board_name,
              this.elearning.e_learning.id
            );

            this.$router.push({
              path: `/e_learnings/${this.$route.params.board_name.toLowerCase()}/${this.$route.params.company.toLowerCase()}/${
                this.$route.params["board_id"]
              }`
            });
          }
        },
        onCancel: () => {}
      });
    },
    isExistsTitle(title) {
      if (!title.length) {
        this.$error("제목을 입력해주세요");
        return;
      }
      return true;
    },
    isTitleTooLong(title) {
      if (title.length >= 100) {
        this.$error("제목은 100자 이내로 입력해주세요");
        return;
      }
      return true;
    },
    isExistsContent(content) {
      if (!content.length) {
        this.$error("내용을 입력해주세요");
        return;
      }
      return true;
    },
    isContentTooLong(content) {
      if (content.length > 5000000) {
        this.$error("내용은 5M 이상 업로드 할 수 없습니다.");
        return;
      }
      return true;
    }
  }
};

export default mixins;
