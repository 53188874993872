<template>
  <div class="container avatar-container-wrapper">
    <div class="avatar-container">
      <img class="avatar" :src="profile.avatar" />
    </div>
    <Card :padding="100">
      <div v-if="profile.user">
        <p style="margin-top: -10px">
          <span v-if="profile.user" class="emphasis">{{
            profile.user.username
          }}</span>
          <!--
          <span v-if="profile.school">@{{profile.school}}</span>
          -->
        </p>
        <!--
        <p v-if="profile.mood">
          {{profile.mood}}
        </p>
        -->
        <hr id="split" />

        <div class="flex-container">
          <div class="left">
            <p>{{ $t("m.UserHomeSolved") }}</p>
            <p class="emphasis">{{ profile.accepted_number }}</p>
          </div>
          <div class="middle">
            <p>{{ $t("m.UserHomeserSubmissions") }}</p>
            <p class="emphasis">{{ profile.submission_number }}</p>
          </div>
          <div class="right">
            <p>{{ $t("m.UserHomeScore") }}</p>
            <p class="emphasis">{{ profile.total_score }}</p>
          </div>
        </div>
        <div id="problems">
          <div v-if="problems.length">
            {{ $t("m.List_Solved_Problems") }}
            <Poptip
              v-if="refreshVisible"
              trigger="hover"
              placement="right-start"
            >
              <Icon type="ios-help-outline"></Icon>
              <div slot="content">
                <p>
                  If you find the following problem id does not exist,<br />
                  try to click the button.
                </p>
                <Button type="info" @click="freshProblemDisplayID"
                  >regenerate</Button
                >
              </div>
            </Poptip>
          </div>
          <!-- <p v-else>{{$t('m.UserHomeIntro')}}</p> -->
          <p v-else>
            두뇌 건강 및 치매 예방의 최고의 방법은 Problem Solving 입니다.
          </p>
          <div class="btns">
            <div
              class="problem-btn"
              v-for="problemID of problems"
              :key="problemID"
            >
              <Button type="ghost" @click="goProblem(problemID)">{{
                problemID
              }}</Button>
            </div>
          </div>
        </div>
        <!-- <div id="icons">
          <a :href="profile.github">
            <Icon type="social-github-outline" size="30"></Icon>
          </a>
          <a :href="'mailto:'+ profile.user.email">
            <Icon class="icon" type="ios-email-outline" size="30"></Icon>
          </a>
          <a :href="profile.blog">
            <Icon class="icon" type="ios-world-outline" size="30"></Icon>
          </a>
        </div> -->
      </div>
    </Card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import time from "@/utils/time";
import api from "@oj/api";

export default {
  data() {
    return {
      username: "",
      profile: {},
      problems: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["changeDomTitle"]),
    init() {
      this.username = this.$route.query.username;
      api.getUserInfo(this.username).then(res => {
        this.changeDomTitle({ title: res.data.data.user.username });
        this.profile = res.data.data;
        this.getSolvedProblems();
        let registerTime = time.utcToLocal(
          this.profile.user.create_time,
          "YYYY-MM-D"
        );
      });
    },
    getSolvedProblems() {
      let ACMProblems = this.profile.acm_problems_status.problems || {};
      let OIProblems = this.profile.oi_problems_status.problems || {};
      // todo oi problems
      let ACProblems = [];
      for (let problems of [ACMProblems, OIProblems]) {
        Object.keys(problems).forEach(problemID => {
          if (problems[problemID]["status"] === 0) {
            ACProblems.push(problems[problemID]["_id"]);
          }
        });
      }
      ACProblems.sort();
      this.problems = ACProblems;
    },
    async goProblem(problemID) {
      const result = await api.getProblemID(problemID);
      this.$router.push({
        name: "problem-details",
        params: { problemID: result.data.data.id }
      });
    },
    freshProblemDisplayID() {
      api.freshDisplayID().then(res => {
        this.$success("Update successfully");
        this.init();
      });
    }
  },
  computed: {
    refreshVisible() {
      if (!this.username) return true;
      if (this.username && this.username === this.$store.getters.user.username)
        return true;
      return false;
    }
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  width: 75%;
  margin: 170px auto;
  text-align: center;
  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .avatar-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    top: -90px;
    .avatar {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      box-shadow: 0 1px 1px 0;
    }
  }
  .emphasis {
    font-size: 20px;
    font-weight: 600;
  }
  #split {
    margin: 20px auto;
    width: 90%;
  }
  .flex-container {
    margin-top: 30px;
    padding: auto 20px;
    .left {
      flex: 1 1;
    }
    .middle {
      flex: 1 1;
      border-left: 1px solid #999;
      border-right: 1px solid #999;
    }
    .right {
      flex: 1 1;
    }
  }
  #problems {
    margin-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    .btns {
      margin-top: 15px;
      .problem-btn {
        display: inline-block;
        margin: 5px;
      }
    }
  }
  #icons {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    .icon {
      padding-left: 20px;
    }
  }
}


</style>

<style>
  @media screen and (max-width: 600px) {
  .avatar-container-wrapper{
    margin: 158px 0 0 0  !important;
    width: 100% !important;
  }
  .avatar-container-wrapper .ivu-card-body{
    padding: 82px 0 0 0 !important;
  }
}
</style>