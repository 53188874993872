import { ajax } from "@/pages/oj/api";

export default {

    getAllReference(){
        return ajax('references', 'get')
    },
    getExamTypeReference(exam_type){
        return ajax('references', 'get', {
            params:{
                exam_type
            }
        })
    },
    getReferenceDetail(reference_id){
        return ajax(`references/${reference_id}`, 'get')
    },

};
