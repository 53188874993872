import { ajax } from "@/pages/oj/api";

export default {
    login (data) {
        return ajax('login', 'post', {
            data
        })
    },
    checkUsernameOrEmail (username, email) {
        return ajax('check_username_or_email', 'post', {
            data: {
                username,
                email
            }
        })
    },
    // 회원가입
    register (data) {
        return ajax('register', 'post', {
            data
        })
    },
    logout () {
        return ajax('logout', 'get')
    },
    getCaptcha () {
        return ajax('captcha', 'get')
    },
    getUserInfo (username = undefined) {
        return ajax('profile', 'get', {
            params: {
                username
            }
        })
    },
    updateProfile (profile) {
        return ajax('profile', 'put', {
            data: profile
        })
    },
    freshDisplayID (userID) {
        return ajax('profile/fresh_display_id', 'get', {
            params: {
                user_id: userID
            }
        })
    },
    twoFactorAuth (method, data) {
        return ajax('two_factor_auth', method, {
            data
        })
    },
    tfaRequiredCheck (username) {
        return ajax('tfa_required', 'post', {
            data: {
                username
            }
        })
    },
    getSessions () {
        return ajax('sessions', 'get')
    },
    deleteSession (sessionKey) {
        return ajax('sessions', 'delete', {
            params: {
                session_key: sessionKey
            }
        })
    },
    applyResetPassword (data) {
        return ajax('apply_reset_password', 'post', {
            data
        })
    },
    resetPassword (data) {
        return ajax('reset_password', 'post', {
            data
        })
    },
    changePassword (data) {
        return ajax('change_password', 'post', {
            data
        })
    },
    changeEmail (data) {
        return ajax('change_email', 'post', {
            data
        })
    },

    getFavorite(user_id){
        return ajax('favorite', 'get', {
            params: {
                user_id
            }
        })
    },
    postFavorite(data){
        return ajax('favorite', 'post',{
            data
        })
    },
    deleteFavorite (data) {
        return ajax('favorite', 'delete',{
            params: {
                ...data
            }
        })
    },
    checkUserProblemAuth(problem_id){
        return ajax("user_problem_auth/", "get", {
            params:{
                problem_id
            }
        })
    },

};
