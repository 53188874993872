const validation = {
  isValidQna(user) {
    
    if (!user.profile) return false;
    if (!user.profile.mincoding_auth) return false;
    if (!user.profile.mincoding_auth.router_name) return false;
    if (!user.profile.mincoding_auth.is_qna_visible) return false;
    // 두가지 모두 공백값이면 넘긴다
  
    // verify_email 문자열이 0보다 클떄
    // 이메일 검증 
    const isValidEmail = this.isValidEmail(user);
    const isValidUserName = this.isValidUsername(user);
    if(isValidEmail === true && isValidUserName === true) return true;
    return false;
  
  },
  isValidAnnounce(user){
    // console.log(this);
    // console.log(user);
    if (!user.profile) return false;
    if (!user.profile.mincoding_auth) return false;
    if (!user.profile.mincoding_auth.router_name) return false;
    if (!user.profile.mincoding_auth.is_notice_visible) return false;
  
    const isValidEmail = this.isValidEmail(user);
    const isValidUserName = this.isValidUsername(user);
    
  
    if(isValidEmail === true && isValidUserName === true) return true;
    
  
    return false;
  },
  isValidEmail(user) {
    let flag = false;
    // verify_email의
    // email이 존재하면서 length가 0보다 클떄
    // 즉 문자열이 존재하면서 조건을 만족해야한다
    
    if (
      user?.profile?.mincoding_auth?.verify_email &&
      user?.profile?.mincoding_auth?.verify_email.length > 0
    ) {

      const zigbangEmailFLag = user?.profile?.user.email.indexOf("@zigbang.com");
      const zigbangGithubFlag =user.profile.github.toLowerCase() === "sds";
      if (
        zigbangEmailFLag  >
        -1 && zigbangGithubFlag
      ) {
        flag = true;
      }

      
      if (
        user?.profile?.user?.email.indexOf(
          user?.profile?.mincoding_auth?.verify_email.trim()
        ) > -1
      ) {
        flag = true;
      }
    }
  
    if (!user?.profile?.mincoding_auth?.verify_email) flag = true;
    return flag;
  },
  isValidUsername(user) {
    let flag = false;
    // console.log(user.profile.user);
    if(user?.profile?.mincoding_auth?.verify_username && user?.profile?.mincoding_auth?.verify_username.length >0){
      if(user?.profile?.user?.username.slice(0,8) === user?.profile?.mincoding_auth?.verify_username.trim()){
        flag = true;
      }
    }
    // console.log(user.profile.user.username.slice(0,8));
    // console.log(user.profile.mincoding_auth.verify_username)
    if (!user?.profile?.mincoding_auth?.verify_username) flag = true;
    // console.log(flag);
    return flag
  },
  isValidContest(user) {
    // if (!this.router_name) return false;
    if (!user.profile) return false;
    if (!user.profile.mincoding_auth) return false;
    if (!user.profile.mincoding_auth.router_name) return false;
    if (!user.profile.mincoding_auth.is_contest_visible) return false;
    const isValidEmail = this.isValidEmail(user);
    const isValidUserName = this.isValidUsername(user);
    if (isValidEmail === true && isValidUserName === true) return true;
    return false;
  },
}

export default validation;