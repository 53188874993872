<template>
  <div>
  <div v-highlight v-html="convertCode" class="ck-content"  ref="highlight" style="word-break: break-all;">
  </div>
</div>
</template>

<script>
// import { fsync } from 'fs';
// import fs from 'fs';

import showDown from "showdown"
export default {
  name: "Boardhighlight",
  data() {
    return {
      converter:null,
      styleObject: {
        "border-left": "2px solid green"
      }
    };
  },
  props: {
    language: {
      type: String
    },
    code: {
      required: true,
    }
  },
  computed:{
    data(){
      this.converter.makeHtml(this.code)
    },
    convertCode(){
      return this.code?.replace(
          /<a\s+([^>]*)download="[^"]*"\s*href="([^"]+)">([^<]+)<\/a>/g,
          (match, attributes, href, textContent) => {
            // 파일명 추출
            let fileName = href.split("/").pop();

            // href를 "/public/upload/파일명" 형태로 변경
            let newHref = `/public/upload/${fileName}`;

            return `<a ${attributes} rel="noopener noreferrer" download="${textContent}" href="${newHref}">${textContent}</a>`;
          }
      );


    }
  },
  created(){
    this.converter = new showDown.Converter();
  },  
  mounted(){
    this.$emit("callParentFunction",this.$refs.highlight.innerText);
    
    
  },
  methods:{

}
};
</script>

<style>

.main-code-highlight .markdown-body code{
  background-color: #fff !important;
}

.main-code-highlight .hljs{
  background-color: #fff !important;
}



.media{
    position: relative;
    padding-top: 56%;
    width: 100%;
    height: 0;
}

.media >iframe{
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>

