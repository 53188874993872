import { ajax } from "@/pages/oj/api";

export default {

    submitCode (data) {
        return ajax('submission', 'post', {
            data
        })
    },
    submissionExists (problemID) {
        return ajax('submission_exists', 'get', {
            params: {
                problem_id: problemID
            }
        })
    },
    submissionRejudge (id) {
        return ajax('admin/submission/rejudge', 'get', {
            params: {
                id
            }
        })
    },
    updateSubmission (data) {
        return ajax('submission', 'put', {
            data
        })
    },

    getSubmissionList (offset, limit, params) {
        params.limit = limit
        params.offset = offset
        return ajax('submissions', 'get', {
            params
        })
    },
    getContestSubmissionList (offset, limit, params) {
        params.limit = limit
        params.offset = offset
        return ajax('contest_submissions', 'get', {
            params
        })
    },

    getSubmissionListV2 (offset, limit, params) {
        params.limit = limit
        params.offset = offset
        return ajax('submissions/v2', 'get', {
            params
        })
    },
    getContestSubmissionListV2 (offset, limit, params) {
        params.limit = limit
        params.offset = offset
        return ajax('contest_submissions/v2', 'get', {
            params
        })
    },
    getSubmission (id) {
        return ajax('submission', 'get', {
            params: {
                id
            }
        })
    },
};
