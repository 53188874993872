import { ajax } from "@/pages/oj/api";

export default {
    getWebsiteConf (params) {
        return ajax('website', 'get', {
            params
        })
    },

    getAnnouncementList (offset, limit) {
        let params = {
            offset: offset,
            limit: limit
        }
        return ajax('announcement', 'get', {
            params
        })
    },


};
