import { ajax } from "@/pages/oj/api";

export default {

// 230201추가
    getELearningList (params) {
        // params.limit = limit
        // params.offset = offset
        return ajax('e_learnings', 'get', {
            params
        })
    },



// 230131추가
    getELearningDetail(params){
        return ajax(`e_learnings/${params}`, 'get', {
            data: {
                company : "miracom"
            }
        })
    },


// 230131추가
    postELearning(title, content, created_by_id, company, type, content_type){
        return ajax('e_learnings/', 'post',{
            data:{
                title,
                content,
                created_by_id,
                company,
                type,
                content_type
            }
        })
    },

// 230201추가
    putELearning(title, content, type, content_type, id){
        return ajax(`e_learnings/${id}/`, 'put', {
            data: {
                title,
                content,
                type,
                content_type
            }
        })
    },


    deleteELearning(parameter){
        return ajax(`e_learnings/${parameter}`, 'delete');
    },

    postComment(comment, board_id, created_by_id, type){
        return ajax('comment/', 'post',{
            data:{
                comment,
                board_id,
                created_by_id,
                type
            }
        })
    },


    // 230201 추가
    secretELearning(id, type){
        return ajax(`e_learnings/${id}/secret/`, 'put', {
            data:{
                type
            }
        })
    },




}
