import { ajax } from "@/pages/oj/api";

export default {

    getBoardList (params) {

        return ajax('board-list', 'get', {
            params
        })
    },

    getBoardDetail(params){
        return ajax('board', 'get', {
            params: {
                board_id: params
            }
        })
    },
    getBoardAuthCheck(params){
        return ajax('board-auth-check', 'get', {
            params: {
                contest_id: params
            }
        })
    },



    postBoard(data){
        return ajax('board/', 'post',{
            data
        })
    },

    putBoard(title, content, parameter, type, isNotice){
        return ajax('board/', 'put', {
            data: {
                title,
                content,
                board_id: parameter,
                type: type,
                isNotice
            }
        })
    },


    deleteBoard(parameter){
        return ajax('board/', 'delete', {
            params: {
                board_id: parameter
            }
        })
    },

    postComment(comment, board_id, created_by_id, type){
        return ajax('comment/', 'post',{
            data:{
                comment,
                board_id,
                created_by_id,
                type
            }
        })
    },

    putComment(comment_id,comment){
        return ajax('comment/', 'put', {
            data: {
                comment_id,
                comment
            }
        })
    },

    deleteComment(comment_id){
        return ajax('comment/', 'delete', {
            params: {
                comment_id
            }
        })
    },

    secretBoard(board_id, type){
        return ajax('board-secret', 'put', {
            data:{
                board_id,
                type
            }
        })
    },

    secretComment(comment_id, type){
        return ajax('comment-secret', 'put', {
            data:{
                comment_id,
                type
            }
        })
    },

    changeProcess(board_id, process) {
        return ajax(`board/${board_id}/process/`, 'put', {
            data: {
                process
            }
        })
    },
    getProcess(compnay) {
        return ajax(`board/process/`, 'get', {
            params:{
                company:compnay
            }
        })
    },

    changeAnnounce(board_id, isNotice){
        return ajax('board/change-announce/', 'put', {
            data:{
                board_id,
                isNotice
            }
        })
    },


}
