import { ajax } from "@/pages/oj/api";

export default {
    getLanguages () {
        return ajax('languages', 'get')
    },
    getProblemTagList () {
        return ajax('problem/tags', 'get')
    },
    getProblemList (offset, limit, searchParams) {
        let params = {
            paging: true,
            offset,
            limit
        }
        Object.keys(searchParams).forEach((element) => {
            if (searchParams[element]) {
                params[element] = searchParams[element]
            }
        })
        return ajax('problem', 'get', {
            params: params
        })
    },
    pickone () {
        return ajax('pickone', 'get')
    },
    getProblemID (problemID) {
        return ajax('problemid', 'get', {
            params: {
                problem_id: problemID
            }
        })
    },
    getContestProblemList (contestId) {
        return ajax('contest/problem', 'get', {
            params: {
                contest_id: contestId
            }
        })
    },
    // soruce도 가져오는 코드 추가
    getProblemAnswer (problemID) {
        return ajax('problem/answer', 'get', {
            params: {
                problem_id: problemID
            }
        })
    },
    getProblem (problemID) {
        return ajax('problem', 'get', {
            params: {
                problem_id: problemID
            }
        })
    },

    getContestProblem (problemID, contestID) {
        return ajax('contest/problem', 'get', {
            params: {
                contest_id: contestID,
                problem_id: problemID
            }
        })
    },
    getContestProblemAnswer (problemID, contestID) {
        return ajax('contest/problem/answer', 'get', {
            params: {
                contest_id: contestID,
                problem_id: problemID
            }
        })
    },

    //v2

    // soruce도 가져오는 코드 추가
    getProblemAnswerV2 (problemID) {
        return ajax('problem/answer/v2', 'get', {
            params: {
                problem_id: problemID
            }
        })
    },
    getProblemV2 (problemID) {
        return ajax('problem/v2', 'get', {
            params: {
                problem_id: problemID
            }
        })
    },

    getContestProblemV2 (problemID, contestID) {
        return ajax('contest/problem/v2', 'get', {
            params: {
                contest_id: contestID,
                problem_id: problemID
            }
        })
    },
    getContestProblemAnswerV2 (problemID, contestID) {
        return ajax('contest/problem/answer/v2', 'get', {
            params: {
                contest_id: contestID,
                problem_id: problemID
            }
        })
    },

};
